import {storeToRefs} from 'pinia';
import type {RouteLocationNormalized} from 'vue-router';
import {abortNavigation, defineNuxtRouteMiddleware, navigateTo} from '#app';
import {useAuthStore} from '~/store/global/auth';
import {pageNames} from '~/lib/pageHelper';

/**
 * List of pages that are available for unauthenticated users
 */
const publicPages: string[] = [
	pageNames.index,
	pageNames.login,
	pageNames.tocDeclined,
	pageNames.stigasQuestionnairePublic,
	pageNames.help
];

/**
 * Check if requested page should only be available for authenticated users
 * @param to
 */
const shouldBeAuthenticated = (to: RouteLocationNormalized) => {
	if (to.path === '/') {
		return false;
	}

	const toName = to.name?.toString();
	if (toName && publicPages.includes(toName)) {
		return false;
	}

	// Logout has own redirect handling for when user is not logged in
	if (toName === pageNames.logout) {
		return false;
	}

	return true;
};

export default defineNuxtRouteMiddleware((to) => {
	const {authenticated} = storeToRefs(useAuthStore());

	/**
	 * When user is authenticated and tries to access login page, redirect to redirect param or companies page
	 */
	if (authenticated.value && to?.name === pageNames.login) {
		if (to.query.redirect) {
			return navigateTo(to.query.redirect as string);
		}

		abortNavigation();
		return navigateTo('/companies');
	}

	/**
	 * When user is authenticated and tries to access index page, redirect to companies page
	 */
	if (authenticated.value && to?.name === pageNames.index) {
		abortNavigation();
		return navigateTo('/companies');
	}

	/**
	 * When user is not authenticated and tries to access a page that should be authenticated, redirect to login page
	 */
	if (!authenticated.value && shouldBeAuthenticated(to)) {
		abortNavigation();
		return navigateTo({name: pageNames.login, query: {redirect: to.fullPath}});
	}
});
