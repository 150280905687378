import { default as dashboardugNntMPNbHMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/dashboard.vue?macro=true";
import { default as _91questionKey_93FEJ5DwGiM2Meta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/inventarisatie/[topicKey]/[questionKey].vue?macro=true";
import { default as end9VkneAFzkMMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/inventarisatie/[topicKey]/end.vue?macro=true";
import { default as indexEvQItqgNU8Meta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/inventarisatie/[topicKey]/index.vue?macro=true";
import { default as index3TvHJZRFSRMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/inventarisatie/index.vue?macro=true";
import { default as inventarisatiedfe7eugNRhMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/inventarisatie.vue?macro=true";
import { default as editIgX8zG4ANtMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/plan-van-aanpak/[actionId]/edit.vue?macro=true";
import { default as index0tN3GsNuC6Meta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/plan-van-aanpak/[actionId]/index.vue?macro=true";
import { default as indexJrgEO7cgP4Meta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/plan-van-aanpak/index.vue?macro=true";
import { default as plan_45van_45aanpak5OxvlbclycMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/plan-van-aanpak.vue?macro=true";
import { default as aanmakenuIRikjkIKdMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/rapportage/aanmaken.vue?macro=true";
import { default as index5zTddmN8uYMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/rapportage/index.vue?macro=true";
import { default as rapportageFl8UE7dWeZMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/rapportage.vue?macro=true";
import { default as editfnuIbu2C2vMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/risicos/[riskId]/edit.vue?macro=true";
import { default as indexXgcPHmg9W8Meta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/risicos/[riskId]/index.vue?macro=true";
import { default as indexEBzs6OQBoCMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/risicos/index.vue?macro=true";
import { default as risicosTpmt3JELImMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/risicos.vue?macro=true";
import { default as indexOGJJ0PTLzhMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/stigas/werknemersvragenlijst/index.vue?macro=true";
import { default as _91rieId_93zgXvcqKCgBMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId].vue?macro=true";
import { default as editF0vMufhVerMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/bedrijfsinformatie/edit.vue?macro=true";
import { default as index7HL9ZnswqKMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/bedrijfsinformatie/index.vue?macro=true";
import { default as bedrijfsinformatieD4a75YHblPMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/bedrijfsinformatie.vue?macro=true";
import { default as _91userId_93xc0uN75hGDMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/gebruikers/[userId].vue?macro=true";
import { default as index99Av7L2mLfMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/gebruikers/index.vue?macro=true";
import { default as uitnodigenbGRWdH48pSMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/gebruikers/uitnodigen.vue?macro=true";
import { default as gebruikerseO3wrkTkDdMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/gebruikers.vue?macro=true";
import { default as indexPK2C8uJOsPMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/index.vue?macro=true";
import { default as editWeIIbEEk0WMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie/[accidentId]/edit.vue?macro=true";
import { default as indexMSkAA3SLTtMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie/[accidentId]/index.vue?macro=true";
import { default as _91accidentId_93A3AiV0GmSWMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie/[accidentId].vue?macro=true";
import { default as indexlCMjg7475cMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie/index.vue?macro=true";
import { default as newxGOZP2Z6J2Meta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie/new.vue?macro=true";
import { default as ongevallenregistratie2pULwJ8tZlMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie.vue?macro=true";
import { default as editMxxOBOB9HQMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/rie/[rieId]/edit.vue?macro=true";
import { default as indexW5RMXoRRuMMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/rie/[rieId]/index.vue?macro=true";
import { default as indexgmwQ1xeVjtMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/rie/index.vue?macro=true";
import { default as newJbN2hrz3U8Meta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/rie/new.vue?macro=true";
import { default as riekGyzsT9QHDMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/rie.vue?macro=true";
import { default as _91id_93dhQD3UVNcoMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/verantwoordelijken/[id].vue?macro=true";
import { default as indexJB8h8isp3cMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/verantwoordelijken/index.vue?macro=true";
import { default as verantwoordelijken3mC7DaSmiJMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/verantwoordelijken.vue?macro=true";
import { default as _91companyId_93MPxBZYpAVIMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId].vue?macro=true";
import { default as indexsjibeH8IXhMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/companies/index.vue?macro=true";
import { default as newFcVuWjGY1qMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/companies/new.vue?macro=true";
import { default as help1ph8bCLJX7Meta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/help.vue?macro=true";
import { default as indexUj7xe3dz3GMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/index.vue?macro=true";
import { default as loginxh0QtaWiC1Meta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/login.vue?macro=true";
import { default as logoutOfMSwYJvgGMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/logout.vue?macro=true";
import { default as editKaD0ma019WMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/persoonsgegevens/edit.vue?macro=true";
import { default as indexOTDz03P0JGMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/persoonsgegevens/index.vue?macro=true";
import { default as persoonsgegevenswEJahJkygTMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/persoonsgegevens.vue?macro=true";
import { default as vragenlijstOWVEtGWK1HMeta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/stigas/vragenlijst.vue?macro=true";
import { default as voorwaarden_45afgewezenYjnX9Z0VH8Meta } from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/voorwaarden-afgewezen.vue?macro=true";
export default [
  {
    name: _91companyId_93MPxBZYpAVIMeta?.name,
    path: "/:companyId()",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId].vue"),
    children: [
  {
    name: "companyId-rieId",
    path: ":rieId()",
    meta: _91rieId_93zgXvcqKCgBMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId].vue"),
    children: [
  {
    name: dashboardugNntMPNbHMeta?.name ?? "companyId-rieId-dashboard",
    path: "dashboard",
    meta: dashboardugNntMPNbHMeta || {},
    alias: ["","dashboard"],
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/dashboard.vue")
  },
  {
    name: inventarisatiedfe7eugNRhMeta?.name,
    path: "inventarisatie",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/inventarisatie.vue"),
    children: [
  {
    name: _91questionKey_93FEJ5DwGiM2Meta?.name ?? "companyId-rieId-inventarisatie-topicKey-questionKey",
    path: ":topicKey()/:questionKey()",
    meta: _91questionKey_93FEJ5DwGiM2Meta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/inventarisatie/[topicKey]/[questionKey].vue")
  },
  {
    name: end9VkneAFzkMMeta?.name ?? "companyId-rieId-inventarisatie-topicKey-end",
    path: ":topicKey()/end",
    meta: end9VkneAFzkMMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/inventarisatie/[topicKey]/end.vue")
  },
  {
    name: indexEvQItqgNU8Meta?.name ?? "companyId-rieId-inventarisatie-topicKey",
    path: ":topicKey()",
    meta: indexEvQItqgNU8Meta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/inventarisatie/[topicKey]/index.vue")
  },
  {
    name: index3TvHJZRFSRMeta?.name ?? "companyId-rieId-inventarisatie",
    path: "",
    meta: index3TvHJZRFSRMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/inventarisatie/index.vue")
  }
]
  },
  {
    name: plan_45van_45aanpak5OxvlbclycMeta?.name,
    path: "plan-van-aanpak",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/plan-van-aanpak.vue"),
    children: [
  {
    name: editIgX8zG4ANtMeta?.name ?? "companyId-rieId-plan-van-aanpak-actionId-edit",
    path: ":actionId()/edit",
    meta: editIgX8zG4ANtMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/plan-van-aanpak/[actionId]/edit.vue")
  },
  {
    name: index0tN3GsNuC6Meta?.name ?? "companyId-rieId-plan-van-aanpak-actionId",
    path: ":actionId()",
    meta: index0tN3GsNuC6Meta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/plan-van-aanpak/[actionId]/index.vue")
  },
  {
    name: indexJrgEO7cgP4Meta?.name ?? "companyId-rieId-plan-van-aanpak",
    path: "",
    meta: indexJrgEO7cgP4Meta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/plan-van-aanpak/index.vue")
  }
]
  },
  {
    name: rapportageFl8UE7dWeZMeta?.name,
    path: "rapportage",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/rapportage.vue"),
    children: [
  {
    name: aanmakenuIRikjkIKdMeta?.name ?? "companyId-rieId-rapportage-aanmaken",
    path: "aanmaken",
    meta: aanmakenuIRikjkIKdMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/rapportage/aanmaken.vue")
  },
  {
    name: index5zTddmN8uYMeta?.name ?? "companyId-rieId-rapportage",
    path: "",
    meta: index5zTddmN8uYMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/rapportage/index.vue")
  }
]
  },
  {
    name: risicosTpmt3JELImMeta?.name,
    path: "risicos",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/risicos.vue"),
    children: [
  {
    name: editfnuIbu2C2vMeta?.name ?? "companyId-rieId-risicos-riskId-edit",
    path: ":riskId()/edit",
    meta: editfnuIbu2C2vMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/risicos/[riskId]/edit.vue")
  },
  {
    name: indexXgcPHmg9W8Meta?.name ?? "companyId-rieId-risicos-riskId",
    path: ":riskId()",
    meta: indexXgcPHmg9W8Meta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/risicos/[riskId]/index.vue")
  },
  {
    name: indexEBzs6OQBoCMeta?.name ?? "companyId-rieId-risicos",
    path: "",
    meta: indexEBzs6OQBoCMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/risicos/index.vue")
  }
]
  },
  {
    name: indexOGJJ0PTLzhMeta?.name ?? "companyId-rieId-stigas-werknemersvragenlijst",
    path: "stigas/werknemersvragenlijst",
    meta: indexOGJJ0PTLzhMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/[rieId]/stigas/werknemersvragenlijst/index.vue")
  }
]
  },
  {
    name: bedrijfsinformatieD4a75YHblPMeta?.name,
    path: "bedrijfsinformatie",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/bedrijfsinformatie.vue"),
    children: [
  {
    name: editF0vMufhVerMeta?.name ?? "companyId-bedrijfsinformatie-edit",
    path: "edit",
    meta: editF0vMufhVerMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/bedrijfsinformatie/edit.vue")
  },
  {
    name: index7HL9ZnswqKMeta?.name ?? "companyId-bedrijfsinformatie",
    path: "",
    meta: index7HL9ZnswqKMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/bedrijfsinformatie/index.vue")
  }
]
  },
  {
    name: gebruikerseO3wrkTkDdMeta?.name,
    path: "gebruikers",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/gebruikers.vue"),
    children: [
  {
    name: _91userId_93xc0uN75hGDMeta?.name ?? "companyId-gebruikers-userId",
    path: ":userId()",
    meta: _91userId_93xc0uN75hGDMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/gebruikers/[userId].vue")
  },
  {
    name: index99Av7L2mLfMeta?.name ?? "companyId-gebruikers",
    path: "",
    meta: index99Av7L2mLfMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/gebruikers/index.vue")
  },
  {
    name: uitnodigenbGRWdH48pSMeta?.name ?? "companyId-gebruikers-uitnodigen",
    path: "uitnodigen",
    meta: uitnodigenbGRWdH48pSMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/gebruikers/uitnodigen.vue")
  }
]
  },
  {
    name: indexPK2C8uJOsPMeta?.name ?? "companyId",
    path: "",
    meta: indexPK2C8uJOsPMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/index.vue")
  },
  {
    name: ongevallenregistratie2pULwJ8tZlMeta?.name,
    path: "ongevallenregistratie",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie.vue"),
    children: [
  {
    name: _91accidentId_93A3AiV0GmSWMeta?.name,
    path: ":accidentId()",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie/[accidentId].vue"),
    children: [
  {
    name: editWeIIbEEk0WMeta?.name ?? "companyId-ongevallenregistratie-accidentId-edit",
    path: "edit",
    meta: editWeIIbEEk0WMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie/[accidentId]/edit.vue")
  },
  {
    name: indexMSkAA3SLTtMeta?.name ?? "companyId-ongevallenregistratie-accidentId",
    path: "",
    meta: indexMSkAA3SLTtMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie/[accidentId]/index.vue")
  }
]
  },
  {
    name: indexlCMjg7475cMeta?.name ?? "companyId-ongevallenregistratie",
    path: "",
    meta: indexlCMjg7475cMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie/index.vue")
  },
  {
    name: newxGOZP2Z6J2Meta?.name ?? "companyId-ongevallenregistratie-new",
    path: "new",
    meta: newxGOZP2Z6J2Meta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/ongevallenregistratie/new.vue")
  }
]
  },
  {
    name: riekGyzsT9QHDMeta?.name,
    path: "rie",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/rie.vue"),
    children: [
  {
    name: editMxxOBOB9HQMeta?.name ?? "companyId-rie-rieId-edit",
    path: ":rieId()/edit",
    meta: editMxxOBOB9HQMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/rie/[rieId]/edit.vue")
  },
  {
    name: indexW5RMXoRRuMMeta?.name ?? "companyId-rie-rieId",
    path: ":rieId()",
    meta: indexW5RMXoRRuMMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/rie/[rieId]/index.vue")
  },
  {
    name: indexgmwQ1xeVjtMeta?.name ?? "companyId-rie",
    path: "",
    meta: indexgmwQ1xeVjtMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/rie/index.vue")
  },
  {
    name: newJbN2hrz3U8Meta?.name ?? "companyId-rie-new",
    path: "new",
    meta: newJbN2hrz3U8Meta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/rie/new.vue")
  }
]
  },
  {
    name: verantwoordelijken3mC7DaSmiJMeta?.name,
    path: "verantwoordelijken",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/verantwoordelijken.vue"),
    children: [
  {
    name: _91id_93dhQD3UVNcoMeta?.name ?? "companyId-verantwoordelijken-id",
    path: ":id()",
    meta: _91id_93dhQD3UVNcoMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/verantwoordelijken/[id].vue")
  },
  {
    name: indexJB8h8isp3cMeta?.name ?? "companyId-verantwoordelijken",
    path: "",
    meta: indexJB8h8isp3cMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/[companyId]/verantwoordelijken/index.vue")
  }
]
  }
]
  },
  {
    name: indexsjibeH8IXhMeta?.name ?? "companies",
    path: "/companies",
    meta: indexsjibeH8IXhMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/companies/index.vue")
  },
  {
    name: newFcVuWjGY1qMeta?.name ?? "companies-new",
    path: "/companies/new",
    meta: newFcVuWjGY1qMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/companies/new.vue")
  },
  {
    name: help1ph8bCLJX7Meta?.name ?? "help",
    path: "/help",
    meta: help1ph8bCLJX7Meta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/help.vue")
  },
  {
    name: indexUj7xe3dz3GMeta?.name ?? "index",
    path: "/",
    meta: indexUj7xe3dz3GMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/index.vue")
  },
  {
    name: loginxh0QtaWiC1Meta?.name ?? "login",
    path: "/login",
    meta: loginxh0QtaWiC1Meta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/login.vue")
  },
  {
    name: logoutOfMSwYJvgGMeta?.name ?? "logout",
    path: "/logout",
    meta: logoutOfMSwYJvgGMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/logout.vue")
  },
  {
    name: persoonsgegevenswEJahJkygTMeta?.name,
    path: "/persoonsgegevens",
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/persoonsgegevens.vue"),
    children: [
  {
    name: editKaD0ma019WMeta?.name ?? "persoonsgegevens-edit",
    path: "edit",
    meta: editKaD0ma019WMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/persoonsgegevens/edit.vue")
  },
  {
    name: indexOTDz03P0JGMeta?.name ?? "persoonsgegevens",
    path: "",
    meta: indexOTDz03P0JGMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/persoonsgegevens/index.vue")
  }
]
  },
  {
    name: vragenlijstOWVEtGWK1HMeta?.name ?? "stigas-vragenlijst",
    path: "/stigas/vragenlijst",
    meta: vragenlijstOWVEtGWK1HMeta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/stigas/vragenlijst.vue")
  },
  {
    name: voorwaarden_45afgewezenYjnX9Z0VH8Meta?.name ?? "voorwaarden-afgewezen",
    path: "/voorwaarden-afgewezen",
    meta: voorwaarden_45afgewezenYjnX9Z0VH8Meta || {},
    component: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/pages/voorwaarden-afgewezen.vue")
  }
]